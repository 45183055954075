$width:       2160px;
$height:      1440px;
$margin-size: 60px;

.print-postcard {
  width:  $width;
  height: $height;
  font-size: 28px;

  .container {
    max-width: $width - ($margin-size * 2);
  }

  // Front/"Base" Variant
  &.variant-base {
    .top-row {
      height: $height * 0.15;
      margin-bottom: 16px !important;
    }

    .essentials-container {
      @extend .col-6;
      .essentials {
        .price {
          padding: 32px 48px 8px 48px !important;
          margin-bottom: 24px !important;
          font-size: 48px;
        }

        .address {
          font-size: 44px;
        }

      }
    }

    .website-container {
      @extend .col-6;
      @extend .text-right;
    }

    .two-column-photo-container {
      height: $height * 0.7;

      .photo, .photo .photo-inner, .photo .photo-inner img {
        height: 100%;
        max-height: 100%;
      }

      .agent-card {
        .agent-photo, .brokerage-logo {
          width: $width * 0.15;
          img {
            width: $width * 0.15;
            max-height: inherit;
          }
        }
        
        .name {
          font-size: 40px;
        }

        .details {
          font-size: 32px;
        }
      }
    }
  }

  // Back Variant
  &.variant-back {
    h1 {
      @extend .font-museo-bold;
      @extend .my-3;
      font-size: 64px;
      color: $gray-700;
    }

    .icons {
      @extend .mt-5;
      @extend .mb-5;

      .icon {
        width:  120px;
        height: 120px;
      }

      .descriptor {
        font-size: 40px;
      }
    }

    .listing-description {
      max-height: $height * 0.25;
    }

    .listing-description, .hood-features {
      @extend .text-left;
      font-size: 38px;
      line-height: 53px;
    }

    .hood-features {
      @extend .mb-3;
      @extend .pb-3;

      span {
        @extend .text-uppercase;
      }
    }

    .agent-card {
      @extend .mt-3;
      @extend .pt-3;
      height: auto;
      border-top: 3px solid $primary;

      .agent-photo, .brokerage-logo {
        width: 225px;
      }

      .name {
        font-size: 42px;
      }

      .details {
        @extend .ml-5;
        width: 900px;
        font-size: 36px;
      }
    }
  }
}