@import "./variables";

.nav-container {
    background-color: $primary !important;
    color: $white;
    font-size: $nav-font-size;
    padding: 0;
    min-height: 100%;
    z-index: 10;
  
    @include media-breakpoint-down(md) {
      // display: flex;
      // align-items: center;
      position: fixed;
      top: 0;
      height: $mobile-nav-height !important;
      min-height: $mobile-nav-height !important;
      max-height: $mobile-nav-height !important;
      padding-left: 10px;
    }
  
    nav {
      @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
        flex-direction: column;
      }
  
      .navbar-toggler {
        border-radius: 30px;
        width: 60px;
        height: 60px;
        padding: 0;
        background-color: $white;
        border: 0;
        margin-top: -1rem;
  
        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 174, 239, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            width: 50px;
            height: 50px;
        }
      }
  
      .navbar-brand {
        @extend .px-3;
  
        @include media-breakpoint-down(md) {
          padding: 0 !important;
        }
  
        img {
          padding-bottom: 1rem;
          border-bottom: 2px solid $active-nav-color;
          width: 100%;
    
          @include media-breakpoint-down(md) {
            height: $mobile-nav-height;
            width: auto;
            border-bottom: 0;
          }
        }
      }
  
      #sidebar-nav {
        background-color: $primary;
        z-index: 10;
        flex-direction: column;
        justify-content: start;
  
  
        @include media-breakpoint-down(md) {
          position: absolute;
          top: $mobile-nav-height;
          height: calc(100vh - #{$mobile-nav-height}) !important;
          transition: left 0.35s ease;
          left: 100vw;
          width: 50vw;
          flex-direction: column-reverse;
  
          &:not(.show) {
            display: none;
          }
  
          &.collapsing {
            display: flex;
          }
  
          &.show {
            display: flex;
            left: 50vw;
          }
        }
      }
      
  
      h2 {
        font-family: AvenirNextLTPro-Medium;
        font-weight: bold;
        font-size: 1.5rem;
      }
  
      h4 {
        font-family: AvenirNextLTPro-Medium;
        font-size: $nav-font-size;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
      }    
  
      .nav-link {
        @extend .px-3;
        @extend .py-1;
        // padding: 0.25rem !important;
        //color: $white !important;

        &.text-muted {
          color: $gray-700 !important;
          cursor: default;
        }
    
        &.active {
          background-color: $active-nav-color;
          color: $primary !important;
          font-weight: bold;
          border-radius: 0px;  
        }
      }
    }
  
  }
  
