.extended-listing-stats {
  display: table;

  .listing-stat {
    display: table-row;

    .name, .value {
      @extend .px-2;
      @extend .py-1;
      display: table-cell;
    }

    .name {
      @extend .font-avenir-bold;
    }
  }
}