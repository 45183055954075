$width:       900px;
$height:      3000px;
$margin-size: 20px;

.digital-postcard {
  font-size: 14px;
  width:     $width;

  @include media-breakpoint-down(lg) {
    width: 100vw;
  }

  @extend .digital-flyer;
}