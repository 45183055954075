$width:  1080px;
$height: 1080px;
$margin-size: 40px;

.instagram-templates {
  @extend .facebook-templates;

  font-size: 18px;

  .frame {
    height: $height;
  }
  
  header {
    @include container($width, $margin-size);

    .essentials {
      .price {
        @extend .pb-2;

        font-size: 32px;
      }

      .address {
        font-size: 36px;
      }

      .city-state {
        font-size: 24px;
      }
    }

    .icons {
      .descriptor {
        font-size: 32px;
      }
    }
  }

  section#visual {
    @include relative-visual-dimensions($width, $height, 0.5);

    .agent-card {
      .name {
        font-size: 24px;
      }

      .details {
        font-size: 20px;
      }
    }

    .two-column-photo-container {
      border-top-width:    15px;
      border-bottom-width: 15px;
    }
  }

  section#info {
    @include container($width, $margin-size);

    .icon-container {
      padding: 0;
    }
  }

  &.variant-base {
    section#info {
      .data-card {
        .content {
          font-size: 28px;
        }
      }
    }
  }
}
