.agent-card {
  // Base Variant
  & { 
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    background: white;

    section {
      max-width: 100%;
    }

    .agent-photo {
      width: 200px;

      img {
        width: 100%;
        border-radius: 100%;
      }
    }

    .name {
      @extend .font-avenir-bold;
      color: $gray-700;
      font-size: 22pt;
    }

    .details {
      @extend .mx-2;
      @extend .font-avenir-regular;
      max-width: 100%;
      word-break: break-word;
      color: $gray-600;
      font-size: 17pt;
    }

    .brokerage-logo {
      width: 200px;

      img {
        width: 100%;
      }
    }
  }

  // Vertical Variant
  &.vertical {
    @extend .flex-column;
    @extend .text-center;
    @extend .p-4;
    height: 100%;
    overflow: hidden;

    .agent-photo {
      @extend .mb-3;

      img {
        width: 125px;
        max-height: 125px;
      }
    }

    section {
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;
    }

    .brokerage-logo {

      img {
        width: 125px;
        max-height: 125px;
      }
    }
  }

  // horizontal Variant
  &.horizontal {
    @extend .flex-row;
    @extend .pt-3;
    //height: 200px;


    @include media-breakpoint-up(lg) {
      height: 200px;
    }

    section {
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-start;

      @include media-breakpoint-down(md) {
        flex-direction: column !important;
        align-items: center !important;
        text-align: center !important;
      }
    }

    .agent-photo {
      @extend .mr-4;
      width: 150px;

      @include media-breakpoint-down(md) {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
    }

    .phone-number::before {
      content: "Direct Phone: ";
    }
    
    .email::before {
      content: "Email: ";
    }

    .url {
      @extend .d-none;
    }
  }
}
