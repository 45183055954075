@import "../app/scss/library/variables";
@import "./scss/variables";
@import "~bootstrap/scss/bootstrap";


$hq-font-path: "../app/scss/library/fonts";
$hq-icon-path: "../app/scss/library/icons";

@import "../app/scss/library/fonts";

@import "./scss/nav";
// @import "./scss/purchase_modal";
@import "./scss/components/loader";
@import "./scss/components/essentials";
@import "./scss/components/icons";
@import "./scss/components/two_column_photo";
@import "./scss/components/agent_card";
@import "./scss/components/listing_description";
@import "./scss/components/listing_stats";
@import "./scss/components/photo_grid";
@import "./scss/components/data_card";
@import "./scss/components/extended_listing_stats";
@import "./scss/components/room_details";
@import "./scss/components/virtual_tour";

@import "./scss/products/digital_flyer";
@import "./scss/products/digital_postcard";
@import "./scss/products/print_postcard";
@import "./scss/products/reports";
@import "./scss/products/facebook_templates";
@import "./scss/products/instagram_templates";
@import "./scss/products/pinterest_templates";
@import "./scss/products/twitter_templates";
@import "./scss/products/basic_website";
@import "./scss/products/listing_sheet";

html, body {
  background-color: $bg-color;
  min-height: 100vh;
}

#app, .main-app-container, .main-app-container > .row {
  height: inherit !important;
  min-height: 100vh;
}

.main-app-container {
  padding-top: 0px !important;
  // padding: 0px !important;
}

.content-container {
  margin-top: 30px;

  @include media-breakpoint-down(lg) {
    margin-top: 30px + $mobile-nav-height;
  }

  .loading-container {
    height: 100%;
  }

  .header-container {
    h2 {
      @extend .font-museo-bold;
      color: $primary;
      font-weight: bold;
      margin-bottom: 5px;
    }
    
    h3 {
      @extend .font-museo-bold;
      color: $tertiary;
      margin-top: 5px;
    }

    .actions {
      @extend .d-flex;
      @extend .justify-content-end;
      @extend .align-items-center;

      a {
        @extend .text-uppercase;
        @extend .font-museo-bold;
        @extend .text-center;
        @extend .mr-5;
        @extend .d-block;
        color: $tertiary;
        // border: none;
        // background: none;
        font-size: 14px;

        &:last-of-type {
          @extend .mr-3;
        }

        &:hover {
          text-decoration: none;
        }

        i { 
          @extend .d-block;
          @extend .mb-2;
        }
      }
    }
  }

  #product-preview-container {
    margin-top: 10px;
    margin: 15px 0;
    border: 2px solid #BCBDBE;
    background-color: $white;
    @extend .p-3;

    @include media-breakpoint-down(md) {
      margin-left: -15px;
      margin-right: -15px !important;
      padding: 10px !important;
    }


    &.without-assets {
      margin-right: -30px;
    }

    #product-carousel {
      width: 95%;
      margin: 0 auto;

      .carousel-control-prev {
        @extend .justify-content-start;
        left: -30px;
      }

      .carousel-control-next {
        @extend .justify-content-end;
        right: -30px;
      }
    }
  }
}

button.primary {
  display: block;
  // background-color: $cta-color !important;
  border: 0px;
  border-radius: 10px !important;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem;
}

.product {
  background-color: $white;
  color: $gray-700;
}

div.product {
  overflow: hidden;
}

#variant-selection-modal {
  h2.instructions {
    @extend .font-avenir-bold;
    @extend .mb-3;
  }
}

.text-fit {
  display: inline-block;
  white-space: nowrap;
}
