.virtual-tour {
  a {
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    
    &:hover {
      text-decoration: none;

      svg path {
        fill: $gray-500 !important;
      }

      span {
        color: $gray-500;
      }
    }

    svg {
      width:  120px;
      height: 120px;
    }

    span {
      @extend .font-avenir-bold;
      font-size: 24px;
      color: $gray-700;
    }
  }
}