$width: 900px;

.basic-website {
  @extend .digital-flyer;
  overflow: scroll !important;

  @include media-breakpoint-up(lg) {
    &#product-preview-container {
      width: 900px;
    }
  }

  width: auto;

  .agent-card.horizontal {
    width: 100%;

    .details {
      @extend .mx-5;
    }
  }
}