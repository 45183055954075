@import "../../../app/scss/library/mixins";

.data-card {
  // Base
  & {
    @extend .row;

    @each $name, $color in $dataset-colors {
      &.color-#{$name} {
        .heading, .icon-label {
          color: $color;
        } 
      }
    }

    .icon-label {
      @extend .font-avenir-regular;
      @extend .text-uppercase;
    }

    .heading {
      @extend .font-avenir-bold;
      font-size: 24px;
    }

    .content {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-center;
      // @extend .align-items-center;

      h6 {
        @extend .text-uppercase;
        @extend .font-avenir-bold;
        @extend .mt-2;
      }


      ul.public-schools {
        @include ul-with-image-bullets(20px, 20px, url(#{$hq-icon-path}/png/pin-public-school.png));
      }

      ul.catholic-schools {
        @include ul-with-image-bullets(20px, 20px, url(#{$hq-icon-path}/png/pin-catholic-school.png));
      }

      .transit-stop {
        @extend .d-flex;

        &.street {
          svg {
            @extend .mr-3;
            width: 32px;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }

        &.rail {
          svg {
            @extend .mr-3;
            height: 32px;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }

      .school {
        @extend .mb-1;
      }
    }
  }

  // Horizontal Variant
  &.horizontal {
    .heading-vertical, .heading-fast-facts {
      @extend .d-none;
    }

    .heading-horizontal {
      @extend .col-12;
    }

    .icon-container {
      @extend .col-2;
    }

    .content {
      @extend .col-10;
    }

    .icon-label {
      @extend .d-none;
    }
  }

  // Horizontal Compact Variant
  &.horizontal-compact {
    .heading-horizontal, .heading-vertical, .heading-fast-facts {
      @extend .d-none;
    }

    .icon-container {
      @extend .col-2;
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;

      .icon {
        max-width: 60%;
        
        svg {
          width: 100%;
        }
      }
    }

    .content {
      @extend .col-10;
    }
  }

  // Vertical Variant
  &.vertical {
    .icon-container {
      @extend .row;
      @extend .mb-3;

      .icon {
        @extend .col-4;
        @extend .d-flex;
        @extend .justify-content-center;

        svg {
          width: 100%;
          max-width: 100%;
        }
      }

      .heading-vertical {
        @extend .col-8;
        @extend .d-flex;
        @extend .align-items-center;
      }
    }
    .heading-horizontal, .heading-fast-facts {
      @extend .d-none;
    }

    .icon-label {
      @extend .d-none;
    }
  }

  &.fast-facts {
    @extend .horizontal;

    .heading-horizontal, .heading-vertical {
      @extend .d-none;
    }

    .content {
      .heading-fast-facts {
        @extend .d-block;
        @extend .text-uppercase;
      }
    }
  }
}