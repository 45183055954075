.essentials {
  @extend .font-museo-bold;
  @extend .mb-2;

  .price {
    @extend .pt-3;
    @extend .px-4;
    @extend .pb-1;
    @extend .d-inline-block;
    @extend .mb-3;

    font-size: 22pt;
    background-color: $gray-700;
    color: $white;

    // &::before {
    //   content: "$"
    // }
  }

  .address {
    @extend .font-museo-bold;
    @extend .mb-0;

    font-size: 22px;
    color: $gray-700;
    line-height: 1.0;
  }

  .city-state {
    @extend .font-museo-regular;
    font-weight: 500;
    color: $gray-700;
  }
}
