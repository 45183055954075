.listing-sheet {
  @extend .digital-flyer;
  //width: 100%;
  //overflow: scroll !important;

  .top-row {
    @extend .row;

    .essentials-container {
      @extend .col-12;
      @extend .col-md-6;

      .essentials {
        .price {
          @media print {
            padding-top: 2px !important;
            padding-bottom: 0 !important;
            margin-bottom: 2px !important;
            // border: 1px solid $gray-700;
          }

          font-size: 22px;
        }
        .address {
          font-size: 20px;
        }
      }
    }

    .icons {
      @extend .col-md-6;
      @extend .d-none;
      @extend .d-md-flex;
      margin-left: 0 !important;

      .icon {
        width: 40px !important;
        height: 40px !important;
      }

      .descriptor {
        font-size: 16px;
      }

    }
  }

  .two-column-photo-container {
    border: 0px;

    .photo {
      max-height: 325px;

      .blurred-background {
        @media print {
          display: none !important;
        }
      }

    }

    .agent-card.vertical {
      .name {
        font-size: 20px;
      }
      
      .details {
        font-size: 16px;
      }

      .agent-photo img {
        width: 100px;
        height: 100px;
      }
    }
  }
}