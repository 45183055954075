.two-column-photo-container {
  @extend .row;
  @extend .no-gutters;
  border-top:    10px solid;
  border-bottom: 10px solid;
  // max-height: 600px;

  @each $name, $color in $dataset-colors {
    &.border-color-#{$name} {
      border-color: $color;
    }
  }

  & > .photo, & > .content {
    @extend .px-0;
  }

  .photo {
    @extend .text-center;
    background-color: $gray-700;
    max-height: 600px;
    background-size: 100%;

    .blurred-background {
      @extend .d-block;
      @extend .w-100;
      @extend .position-absolute;
      height: 100%;
    }

    .blurred-background-overlay {
      @extend .position-absolute;
      @extend .w-100;
      height: 100%;
      background-color: rgba($gray-700, 0.3);
    }
  }

  .photo-inner {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .position-relative;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .photo-inner img {
    max-height: 100%;
    max-width: 100%;
  }

  &.size-two-thirds {
    .photo {
      @extend .col-12;
      @extend .col-md-8;
    }

    & > .content {
      @extend .col-12;
      @extend .col-md-4;
    }
  }

  &.size-less-than-half {
    .photo {
      @extend .col-12;
      @extend .col-md-5;
    }

    & > .content {
      @extend .col-12;
      @extend .col-md-7;
    }
  }

  &.size-photo-only {
    border-top:    0px;
    border-bottom: 0px;
    
    .photo {
      @extend .col-12;
    }

    & > .content {
      @extend .d-none;
    }
  }
}