$width:       900px;
$height:      3000px;
$margin-size: 20px;

.digital-flyer {
  @extend .pb-3;
  font-size: 14px;
  width:     $width;

  @include media-breakpoint-down(lg) {
    width: 100vw;
  }

  .container {
    max-width: $width - ($margin-size * 2);
  }

  .essentials {
    .address {
      font-size: 24px;
    }
  }

  .two-column-photo-container {
    @extend .mt-2;
    border-top:    15px solid;
    border-bottom: 15px solid;
    border-color:  $blue;

    .photo {
      max-height: 450px;
    }
  }

  .agent-card {
    .agent-photo, .brokerage-logo {
      width: $width * 0.15;
    }

    .name {
      font-size: 22px;
    }

    .details {
      font-size: 17px;
    }
  }

  .icons {
    @extend .col-12;
    @extend .col-md-6;
    @extend .offset-md-3;
    @extend .mt-5;
    @extend .mb-4;

    .icon {
      width: 60px !important;
      height: 60px !important;
    }

    .descriptor {
      font-size: 20px;
    }
  }

  .listing-stats-container {
    margin: 0 auto;

    .listing-stats {
      font-size: 18px;
      display: block !important;
    }
  }

  .extended-listing-stats, .room-details {
    font-size: 12px;
  }

  h2 {
    @extend .font-museo-bold;
    @extend .pb-3;
    @extend .mb-4;
    @extend .text-center;
    border-bottom: 1px solid $blue;
    // font-size: 48px;
  }

  .fast-facts {
    .icon-container {
      @extend .d-none;
      @extend .d-md-block;
    }

    .content {
      @extend .col-12;
      @extend .col-md-8;
    }

    &:not(.data-card) {
      @extend .pb-3;
      @extend .mb-5;
      border-bottom: 5px solid $blue;
    }
  }
}
