$width:  940px;
$height: 788px;
$margin-size: 40px;

@mixin container($_width, $_margin-size) {
  @extend .container;
  max-width: $_width - ($_margin-size * 2);
}

@mixin relative-visual-dimensions($_width, $_height, $_container-multiplier: 0.5) {
  .two-column-photo-container {
    .photo, .agent-card {
      max-height: $_height * $_container-multiplier;
    }
  }

  .agent-card {
    .agent-photo, .brokerage-logo {
      width: $width * 0.1667;

      img {
        width: $width * 0.1667;
        max-height: inherit;
      }
    }
  }
}

.facebook-templates {
  font-size: 14px;

  .frame {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-between;

    height: $height;
  }

  header {
    @include container($width, $margin-size);
    @extend .d-flex;
    @extend .flex-row;
    @extend .justify-content-between;
    @extend .pb-1;

    .essentials {
      @extend .flex-fill;

      .price {
        font-size: 20px;
      }

      .address {
        font-size: 24px;
      }

      .city-state {
        @extend .font-museo-light;
      }
    }

    .icons {
      @extend .align-self-end;
      @extend .flex-fill;
      @extend .pb-3;

      .descriptor {
        font-size: 16px;
      }
    }
  }

  section#visual {
    @include relative-visual-dimensions($width, $height);

    .agent-card {
      .name {
        font-size: 16px;
      }

      .details {
        font-size: 14px;
      }
    }
  }

  section#info {
    @include container($width, $margin-size);
    @extend .flex-grow-1;
    @extend .py-1;
    @extend .align-self-center;
    
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-around;

    .data-card.horizontal {
      // TODO: Can this be ripped out?
      .icon-container {
        @extend .col-3;
      }

      .heading-horizontal {
        font-size: 32px;
      }

      // TODO: HB this too?
      .content {
        @extend .col-9;
        font-size: 24px;
      }
    }
  }

  &.variant-base {
    section#visual {
      .two-column-photo-container {
        border-color: $lookbook-green-1;
      }
    }

    section#info {
      .data-card {
        @extend .flex-fill;

        .content {
          font-size: 20px;
        }
      }
    }
  }
}
