.photo-grid {
  $border-radius: 2%;

  .image {
    @extend .mb-3;
    text-align: center;
  }

  img {
    @extend .img-fluid;
    max-height: 214px; // Basically 220 - border + padding
  }
}
