$width:  1000px;
$height: 1500px;
$margin-size: 40px;

.pinterest-templates {
  font-size: 24px;

  .container {
    max-width: $width - ($margin-size * 2);
  }

  &.variant-base {
    .two-column-photo-container {
      border-color: $lookbook-green-1;
      border-top-width: 15px;
      border-bottom-width: 15px;
    }

    .data-card {
      .content {
        font-size: 14px;
      }
    }
  }

  .essentials {
    .price {
      font-size: 36px;
    }

    .address {
      font-size: 38px;
    }
  }

  .icons {
    @extend .my-4;

    .icon {
      width:  100px !important;
      height: 100px !important;
    }

    .descriptor {
      font-size: 32px;
    }
  }

  .two-column-photo-container {
    .photo {
      max-height: $height * 0.35;
    }
  }

  .agent-card {
    .agent-photo, .brokerage-logo {
      width: $width * 0.1667;

      img {
        width: $width * 0.1667;
        max-height: inherit;
      }
    }

    .name {
      font-size: 28px;
    }

    .details {
      font-size: 24px;
    }
  }

  .data-card.horizontal-compact {
    .icon-container {
      @extend .col-3;

      .icon-label {
        @extend .font-avenir-regular;
      }
    }

    .content {
      @extend .col-9;
      font-size: 28px;
    }
  }

  .data-card.vertical {
    .icon-container {
      .icon {
        @extend .col-3;
      }
      
      .heading {
        @extend .col-9;
        @extend .pl-5;
        line-height: 48px;
      }
    }
    .heading-vertical {
      font-size: 48px;
    }

    .content {
      @extend .my-5;
      font-size: 40px;
    }
  }
}