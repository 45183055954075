$width:  1024px;
$height: 512px;
$margin-size: 40px;

.twitter-templates {
  .container {
    max-width: $width - ($margin-size * 2);
  }

  .essentials {
    .price {
      @extend .pt-2;
      font-size: 20px;
    }

    .address {
      font-size: 28px;
    }
  }

  .icons {
    .descriptor {
      font-size: 20px;
    }
  }

  &.variant-base {
    .two-column-photo-container {
      border-color: $gray-500;
    }

    .data-card {
      .icon-container {
        @extend .col-3;

        .icon-label {
          @extend .font-avenir-regular;
        }

        .icon {
          max-width: 50%;
        }
      }
      .content {
        @extend .col-9;
        font-size: 14px;
      }
    }
  }

  .two-column-photo-container {
    .photo {
      max-height: $height * 0.5;
    }
    
    .content {
      max-height: $height * 0.5;

      .data-cards-container {
        @extend .p-3;
        height: 100%;

        & > div {
          @extend .d-flex;
          @extend .flex-column;
          @extend .align-items-center;
          @extend .justify-content-center;
        }
      }
    }
  }

  .data-card.horizontal {
    .heading {
      @extend .mb-4;
    }

    .icon-container {
      @extend .col-3;
    }

    .content {
      @extend .col-9;
      font-size: 24px;
    }
  }
  
  .agent-card.horizontal {
    height: $height * 0.2 !important;
    @extend .justify-content-end;
    @extend .p-0;

    .agent-photo, .brokerage-logo {
      height: $height * 0.15;
      width: auto !important;
      @extend .d-flex;
      @extend .justify-content-center;

      img {
        height: 100%;
        width: auto;
      }
    }

    .name {
      font-size: 16px;
    }

    .details {
      font-size: 14px;
    }
  }
}