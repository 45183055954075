.loader {
  .spinner {
    @extend .mb-3;
    @extend .text-center;
  }

  .text {
    font-size: 22pt;
    color: $gray-600;
  }
}
