.room-details {
  .section-header {
    @extend .text-primary;
    @extend .font-avenir-bold;
  }

  .room-details-content {
    table {
      width: 100%;

      td, th {
        @extend .px-2;
        @extend .py-1;
      }

      th {
        @extend .text-primary;
        @extend .font-avenir-regular;
      }
    }
  }
}