.icons {
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-around;

  .bedrooms-icon-container,
    .bathrooms-icon-container,
    .property-type-icon-container {
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;
      @extend .flex-fill;

      .icon {
        @extend .d-flex;
        @extend .align-items-end;

        width:  60px;
        height: 60px;
    
        svg {
          width:  100%;
          height: 100%;

          path {
            // !important is necessary here to override the
            // styles within the svg itself.
            fill: $gray-700 !important;
          }
        }
      }

      .descriptor {
        @extend .font-avenir-bold;
        @extend .text-center;
        color: $gray-700;
      }
    }

  .property-type-icon-container {
    @extend .flex-grow-1;
  }

}
